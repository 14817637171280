let currentFilterValue = ''

const rerenderFilteredList = () => {
  const filteredList = document.querySelectorAll('.organizations-list .result-card[data-category]')
  filteredList.forEach(item => {
    const category = item.dataset.category
    if (
      currentFilterValue &&
      !category
        ?.split(',')
        .map(cat => cat.trim())
        ?.includes(currentFilterValue)
    ) {
      item.classList.add('hidden')
    } else {
      item.classList.remove('hidden')
    }
  })
}

document.addEventListener('DOMContentLoaded', () => {
  const filterSelector = document.querySelector('.filter-block .dropdown')
  if (!filterSelector) return
  const visibleValueEl = filterSelector.querySelector('#filter-value')
  filterSelector.querySelectorAll('li[data-value]').forEach(item => {
    item.addEventListener('click', () => {
      const value = item.dataset.value
      currentFilterValue = value
      visibleValueEl.innerText = value ? `Kategoria: ${value}` : 'Všetky kategórie'
      rerenderFilteredList()
    })
  })
})
